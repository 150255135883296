<template>
  <div class="col-md-12">
    <b-button class="rebutton" @click="$router.back()">
      Back</b-button>

      <h1 class="w-100 text-center">Add User</h1>
      <form name="form" @submit.prevent="handleRegister">
        <div>
          <div class="form-group">
            <label for="name">Display Name</label>
            <input
                v-model="user.displayName"
                v-validate="'required'"
                class="form-control"
                name="name"
            />
            <div v-if="submitted && errors.has('name')" class="alert-danger">
              {{ errors.first('name') }}
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              v-model="user.email"
              v-validate="'required|email|max:50'"
              type="email"
              class="form-control"
              name="email"
            />
            <div v-if="submitted && errors.has('email')" class="alert-danger">
              {{ errors.first('email') }}
            </div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              v-model="user.password"
              v-validate="'required|min:6|max:40'"
              type="password"
              class="form-control"
              name="password"
              ref="password"
            />
            <div
              v-if="submitted && errors.has('password')"
              class="alert-danger"
            >
              {{ errors.first('password') }}
            </div>
          </div>
          <div class="form-group">
            <label for="password">Re-type Password</label>
            <input
                v-model="confirmPassword"
                v-validate="'required|confirmed:password'"
                type="password"
                class="form-control"
                name="password-confirm"
            />
            <div
                v-if="submitted && errors.has('password-confirm')"
                class="alert-danger"
            >
              {{ errors.first('password-confirm') }}
            </div>
          </div>
          <div class="form-group" v-if="isAdmin">
            <label for="Organisation">Organisation</label>
            <b-select
              v-model="user.organisation"
              :options="orgOptions"
              type="text"
              class="form-control"
              name="organisation"
            />
            <div
              v-if="submitted && errors.has('organisation')"
              class="alert-danger"
            >
              {{ errors.first('organisation') }}
            </div>
          </div>
          <div class="form-group position-relative">
            <div v-if="loading" class="loading-button-overlay">
              <BSpinner></BSpinner>
            </div>
            <button class="btn btn-primary btn-block">Register User</button>
          </div>
        </div>
      </form>

      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
  </div>
</template>

<script>
import User from '../models/user';
import UserService from "../services/user.service";

export default {
  name: 'Register',
  components: {},
  data() {
    return {
      user: new User('', '', '', '', ''),
      submitted: false,
      successful: false,
      message: '',
      confirmPassword: '',
      loading: false,
      orgOptions: null
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isAdmin() {
      console.log(this.$store.state.auth.user)
      return this.$store.state.auth.user.is_admin
    }
  },
  mounted() {
    if (this.loggedIn) {
      //this.$router.push('/profile');
    }
    this.getOrgs()
  },
  methods: {
    async getOrgs() {
      let response = await UserService.getOrgs()
      if (response.status === 200) {
        this.orgOptions = response.data.map((org) => org.organisation_name)
      }
    },
    async handleRegister() {
      this.message = ''
      if (!this.isAdmin) {
        this.user.organisation = this.$store.state.auth.user.organisation
      }
      this.submitted = true
      let isValid = await this.$validator.validate()
      if (isValid) {
        try {
          this.loading = true
          let result = await UserService.registerUser(this.user.displayName, this.user.email, this.user.password, this.user.organisation)
          if (result) {
            this.successful = true
            this.message = 'User Created Successfully.'
            this.loading = false
          }
        } catch (e) {
          console.log(e)
          console.log(e.request.status)
          if (e.request.status === 409) {
            this.message = 'Failed to create User. Email address already in use.'
          } else if (e.request.status === 404) {
            this.message = 'Failed to create User. Organisation not found.'
          }else {
            this.message = 'Error creating User. Please try again later.'
          }
          this.successful = false
          this.loading = false
        }
      } else {
        this.successful = false
        this.message = 'Please complete the form above.'
        this.loading = false
      }
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.loading-button-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.1);
}
</style>
